.player-progress {
    position: Fixed;
    bottom: 70px;
    border: 2px solid #153E8F;
    z-index: 2;
}

.progress-bar-fill {
    -webkit-appearance: none;
    position: absolute;
    bottom: -5px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    cursor: pointer;
}

.progress-bar-fill::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 15px;
    height: 15px;
    background: #153E8F; /* Thumb color */
    border-radius: 50%; /* Makes it circular */
  }

.continue-button:hover {
    background-color: rgb(36, 105, 245);
    color: white;
}


/* Tablets (width between 481px and 768px) */
@media only screen and (min-width: 481px) and (max-width: 1024px) {
    button.classic {
        width: auto;
        font-size: 12px;
    }
    .inroduction-footer {
        padding: 10px;
    }
  }

/* Small Phones (width <= 480px) */
@media only screen and (max-width: 480px) {
    .backward, .forward {
        width: 20px !important;
    }
    .play {
        width: 28px !important;
    }
    button.classic {
        width: auto;
        font-size: 12px;
    }
    .inroduction-footer {
        padding: 10px;
    }
}