.menu-icon svg {
    width: 30px; 
    height: 30px; 
}

.introduction-heading {
    color: #1C1C1C;
}
.back-icon:hover {
    background: var(--chakra-colors-gray-100);
    border-radius: 10px;
}

@media only screen and (max-width: 1280px) { 
    .back-icon  {
        width: 40px !important;
        height: 40px !important;
    }
    .menu-icon svg {
        width: 20px; 
        height: 20px; 
    }

    .introduction-heading {
        font-size: 22px !important;
    }
}

/* Small Phones (width <= 480px) */
@media only screen and (max-width: 480px) {
    .introduction-container {
        width: 100% !important;
    }
    .introduction-content {
        margin-top: 0 !important;
    }
    .introduction-heading {
        font-size: 16px !important;
    }
}
  
  /* Tablets (width between 481px and 1024px) */
  @media only screen and (min-width: 481px) and (max-width: 1024px) {
    .introduction-container {
        width: 100% !important;
    }
  }
  
  /* Portrait Orientation */
  @media only screen and (orientation: portrait) {
    
  }
  
  /* Landscape Orientation */
  @media only screen and (orientation: landscape) {
  }
  