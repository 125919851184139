.selectedLanguage {
  color: black;
}
.active {
  background-color: #9dbcf8;
}
.language-dropdown{
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
}

@media (max-width: 767px) {
.language-dropdown {
  left: 15px;
}
}

@media (min-width: 768px) and (max-width: 1023px) {
.language-dropdown {
  left: 60px;
}
}

@media (min-width: 1024px) {
.language-dropdown {
  left: 80px;
}
}