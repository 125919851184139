/* VideoPlayer.css */

.video-player {
  height: 40vh !important;
  width: 50%;
}
.courses-subtitles-container {
  height: 30vh;
  padding-bottom: 5rem;
  overflow: auto;
}

.normal-video {
  height: 450px !important;
  width: 70% !important;
}

.overlay {
  position: absolute;
  width: 47%;
  height: 40vh;
  background-color: rgba(0, 0, 0, 0.3);
  /* Semi-transparent overlay */
  display: flex;
  justify-content: center;
  cursor: pointer;
  z-index: 2;
  /* Overlay appears above the video */
  opacity: 0;
}

.subtitles {
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.subtitle-text {
  flex-shrink: 0;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  color: #1c1c1c;
  box-sizing: border-box;
  white-space: nowrap; /* Prevent text from wrapping to the next line */
}

.transcript-4 .subtitle-text {
  width: auto;
}

.transcript-1,
.transcript-2 {
  font-weight: 700;
  font-size: 18px;
}

.transcript-3 {
  font-weight: 500;
}

.transcript-4, .fourth-line {
  font-style: italic;
  font-size: 17px;
}

.disable-line {
  display: none !important;
}

@media only screen and (max-width: 1280px) {
  .overlay {
    height: 40vh !important;
  }
}
@media (min-width: 1025px) {
  .courses-subtitles-container {
    height: 40vh;
    padding-bottom: 5rem;
    overflow: auto;
  }
}

@media only screen and (max-width: 480px) {
  .subtitles {
    flex-wrap: wrap;
  }

  .subtitle-text {
    padding-bottom: 0 !important;
  }

  .separator {
    width: 80%;
    background-color: gray;
    margin: 10px auto;
  }

  .subtitles:last-of-type::after {
    content: none;
  }

  .transcript-4, .fourth-line {
    margin-top: 20px !important;
    font-weight: 700;
  }

  .transcript-3,
  .transcript-4 {
    font-size: 14px;
  }
  .video-player,
  .overlay {
    height: 32vh !important;
  }
  .courses-subtitles-container {
    height: 50vh;
    padding-bottom: 5rem;
    overflow: auto;
  }
}
