.home-page-container {
  width: 93%;
  margin: 0 auto;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.2);
}

.banner-container {
  background: url('src/asset/images/banner/homepage/banner-toolika.svg')
    no-repeat;
  width: auto;
  background-size: cover;
}

@media only screen and (max-width: 768px) {
  .banner-container {
    background: url('src/asset/images/banner-mobile.svg') no-repeat center;
    background-size: cover;
    width: 100%;
  }
}

.our-methods {
  width: 50%;
}

.our-methods-icon {
  border-radius: 50%;
  padding: 5px;
  background-color: #e8effd;
}

.we-beleive-icon {
  background-color: #000000;
  border-radius: 50%;
  padding: 5px;
}

.achievments-container,
.joyful-experience-container {
  background-color: #121212;
  height: 190px;
  justify-content: center;
  padding: 0px 35px;
  width: auto;
  overflow: auto;
}
.achievments {
  color: #ffffff;
  padding: 0px 30px;
  text-align: center;
  border-right: 2px solid #ffffff;
  height: auto;
  line-height: 46px;
  font-size: 30px;
}

.achievments > p:nth-child(2) {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.achievments-mobile {
  height: auto;
  width: 100%;
  text-align: center;
  padding: 15px 8px;
  line-height: 46px;
  font-size: 24px;
  border-bottom: 2px solid #ffffff;
}

.achievments-mobile > p:nth-child(2) {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  width: 56%;
  margin: auto;
}

.achievments-mobile:last-child {
  border-bottom: none;
}

.achievments:last-child {
  border-right: none;
  padding-right: 0px;
}

.achievments:first-child {
  padding-left: 0px;
}

.plans-container {
  background-color: #efefef;
  border: 1px solid #e6e6e6;
  border-radius: 20px;
  gap: 3;
  margin-bottom: 65px !important;
}

.plans {
  text-align: center;
  min-width: 126px;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  cursor: pointer;
  color: #000000;
}

.plans.active {
  background: #000000;
  border: 1px solid #000000;
  border-radius: 20px;
  color: #ffffff;
  padding: 10px;
}

.plans-container-mobile .plans {
  border-radius: 8px;
  border: 1px solid #868585;
  font-size: 14px;
  width: 140px;
}

.pricing {
  text-align: center;
  border: 2px solid #dae1ea;
  width: 250px;
  height: 200px;
  padding: 21px 16px;
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.04);
  border-radius: 16px;
}

.pricing.active {
  border-color: #2469f5;
}

.save-tag {
  left: 50px;
  position: relative;
  bottom: 32px;
  border-radius: 16px;
  width: 50%;
  font-size: 14px;
  background-color: #0ebe75;
  color: #ffffff;
}

.pricing > p {
  color: #454545;
  font-weight: 500;
  font-size: 16px;
  line-height: 36px;
}

.pricing > .plan-name {
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  color: #252b2f;
}

.joyful-experience-container {
  margin: 70px 0px !important;
}

.joyful-experience-container > button {
  margin-top: 16px !important;
}

.footer-item > ul > li > a {
  color: #033cc3;
}

.footer-item > ul > li {
  font-weight: 600;
}

.container {
  padding: 0px 40px;
}

.slick-slide img {
  margin: auto;
  cursor: pointer;
}

.slick-arrow.slick-prev,
.slick-arrow.slick-prev:hover,
.slick-arrow.slick-prev:focus {
  background: url('src/asset/images/arrow-right.svg') center no-repeat;
  border-radius: 50%;
  background-size: 25px;
  z-index: 99;
}

.slick-arrow.slick-next,
.slick-arrow.slick-next:hover,
.slick-arrow.slick-next:focus {
  background: url('src/asset/images/arrow-left.svg') center no-repeat;
  border-radius: 50%;
  background-size: 25px;
  z-index: 99;
}

.slick-arrow.slick-prev::before,
.slick-arrow.slick-next::before {
  content: '';
}

@media only screen and (max-width: 768px) {
  .our-students-content iframe {
    width: 330px;
  }
}
