.fourL-control {
    width: 21px;
    height: 21px;
    background-color: #153E8F;
    color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    cursor: pointer;
}
.disable-control {
    background-color: #7A7A7A;
}