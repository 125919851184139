.carouselSlider,
.carouselSliderForAll {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.carouselSlider::-webkit-scrollbar,
.carouselSliderForAll::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.carouselSliderForAll {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.carouselSliderForAll {
  flex-wrap: wrap;
  width: 100%;
  overflow: hidden;
}

.carouselSliderBtn {
  position: fixed;
  z-index: 2000;
}
.carouselSlider,
.carouselSliderForAll {
  width: 100%;
  margin: 0 auto;
  margin-top: 20px;
  overflow: hidden;
  position: relative;
}

.carouselSlider .arrow,
.carouselSliderForAll .arrow {
  position: sticky;
  top: 32%;
  background-color: rgba(255, 255, 255, 0.7);
  border: 1px solid #000;
  border-radius: 0%;
  cursor: pointer;
  width: 20px;
  z-index: 1;
}

.carouselSlider .arrow img,
.carouselSliderForAll .arrow img {
  margin-top: 10px;
  max-width: 100%;
}

.carouselSlider .carousel-left-arrow,
.carouselSliderForAll .carousel-left-arrow {
  width: 40px;
  left: 0;
  background: transparent linear-gradient(180deg, #5f6064 0%, #171717 100%) 0%
    0% no-repeat padding-box;
  height: 40px;
  padding: 15px;
  border-radius: 10px;
  color: #fff;
  border: none;
  font-size: 24px;
  border-radius: 50%;
  opacity: 0.8;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -60px;
  z-index: 99;
}
.carousel-left-arrow:hover {
  background: rgb(15 15 15);
}
.carouselSlider .carousel-right-arrow,
.carouselSliderForAll .carousel-right-arrow {
  width: 40px;
  right: 0;
  background: transparent linear-gradient(180deg, #5f6064 0%, #171717 100%) 0%
    0% no-repeat padding-box;
  height: 40px;
  padding: 15px;
  border-radius: 10px;
  color: #fff;
  border: none;
  font-size: 24px;
  border-radius: 50%;
  opacity: 0.8;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -50px;
  z-index: 99;
}
.carousel-right-arrow:hover {
  background: rgb(15 15 15);
}
.carousel-right-arrow:disabled {
  background-color: #d8d8d8;
}
.carousel-left-arrow:disabled {
  background-color: #d8d8d8;
}

.langSelect > div {
  border: none;
  box-shadow: none;
}
.langSelect:hover,
.langSelect:focus-visible {
  border: none;
}

.langSelect {
  margin-left: 80px;
  margin-top: 0px;
}
#react-select-2-listbox {
  width: 60px;
  display: flex;
  justify-content: center;
  margin-left: 30px;
  margin-top: 18px;
  border: 1px solid #d8d8d8;
}
.chakra-radio__control[data-checked] {
  background: #2469f5;
  border-color: #2469f5;
}

.react-3d-carousel .slider-container .slider-right div {
  width: 50px;
  position: absolute;
  top: 50%;
  left: 200%;
  margin-top: -20px;
  color: #000;
  transform: translateY(-50%);
  padding: 10px 10px;
  border-top: 2px solid #171717;
  border-right: 2px solid #171717;
  border-bottom: 2px solid #171717;
  border-left: 2px solid #171717;
  height: 50px;
  border-radius: 50%;
  text-align: center;
}
.react-3d-carousel .slider-container .slider-left div {
  position: absolute;
  top: 50%;
  left: -200%;
  margin-top: -20px;
  color: #000;
  transform: translateY(-50%);
  padding: 10px 10px;
  border-top: 2px solid #171717;
  border-right: 2px solid #171717;
  border-bottom: 2px solid #171717;
  border-left: 2px solid #171717;
  height: 50px;
  border-radius: 50%;
  text-align: center;
  width: 50px;
}

@media screen and (min-width: 320px) {
  .react-3d-carousel .slider-container .slider-left div {
    left: -150%;
  }
  .react-3d-carousel .slider-container .slider-right div {
    left: 110%;
  }
}
.carousel .control-prev.control-arrow:before {
  border-right: 8px solid #2469f5 !important;
}

.carousel .control-next.control-arrow:before {
  border-left: 8px solid #2469f5 !important;
}

.carousel .control-dots .dot {
  background: #2469f5 !important;
}

.carousel .control-dots {
  margin: -1px 0 !important;
}
