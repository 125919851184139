.loading-indicator:before {
  content: '';
  background: rgb(224 224 224);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  opacity: 0.8;
}

.loading-indicator:after {
  content: url(../src/asset/images/loader.gif);
  position: fixed;
  width: 100%;
  top: 45%;
  left: 0;
  z-index: 1001;
  color: white;
  text-align: center;
  font-weight: bold;
  font-size: 1.5rem;
  animation-name: rotate;
  animation-duration: 0.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.loading-indicator-mobile:before {
  content: '';
  background: rgb(224 224 224);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  opacity: 0.8;
}

.loading-indicator-mobile:after {
  content: url(../src/asset/images/mobile_loader.gif);
  position: fixed;
  width: 100%;
  top: 45%;
  left: 0;
  z-index: 1001;
  color: white;
  text-align: center;
  font-weight: bold;
  font-size: 1.5rem;
  animation-name: rotate;
  animation-duration: 0.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.swal-button {
  background: transparent linear-gradient(180deg, #2469f5 0%, #033cc3 196%) 0%
    0% no-repeat padding-box !important;
}
.pointer :hover {
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #a9abaf;
  border-radius: 10px;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
  display: block;
  width: 100%;
  height: 60px;
  padding-left: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  font-size: 16px;
}
