.languageContainer:hover {
  cursor: pointer;
}

.slick-track {
  display: flex !important;
}
.slick-slide {
  display: flex !important;
  justify-content: center !important;
}

.slick-list {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
